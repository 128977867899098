import {
  CardActionArea,
  Dialog,
  Divider,
  CardMedia,
  DialogContent,
  Card,
  Grid,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { setURL } from "../../utils/constants";
import { avatarURLs } from "../../Containers/experience/scene/Database";
import { joinCall } from "../../agora/agora";
import "./AvatarSelection.css";
import { useHistory } from "react-router-dom";

export default function AvatarSelection() {
  const history = useHistory();
  const onAvatarClick = (id) => {
    setURL(id);
    joinCall();
    history.push("/experience");
  };

  const [selectedAvatar, setselectedAvatar] = useState("");
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        backgroundImage: 'url("/avatarBG.jpg")',
        backgroundSize: "Cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Dialog open={true} maxWidth={"md"}>
        <DialogContent style={{ overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
              width: "100%",
            }}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              <div className="topHeader">Choose your personalised Avatar</div>
              <Divider />
            </div>
            <div className="Avatar_Container">
              {avatarURLs.map((value) => (
                <div
                  className="Card"
                  style={{
                    border:
                      selectedAvatar == value.id ? "1.5px solid #013072" : "",
                  }}
                  onClick={() => {
                    setselectedAvatar(value.id);
                  }}
                >
                  <img className="Card" src={value.image} />
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                width: "100%",
              }}
            >
              <Divider />
              <div className="Container">
                <button
                  className="Next_BTN"
                  disabled={selectedAvatar == ""}
                  onClick={() => {
                    onAvatarClick(selectedAvatar);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
