import React, { useEffect, useState } from "react";
import XRCLogo from "./Assets/XRC Logo.png";
import Pattern from "./Assets/Pattern.png";
import BottomPattern from "./Assets/BottomPattern.png";
import LataImage from "./Assets/Lata.png";
import gramoPhone from "./Assets/noun-gramophone-3021852 1.png";
import Mic from "./Assets/Mic.png";
import Medal from "./Assets/Medal.png";

import "./LandingPage.css";
import ReCAPTCHA from "react-google-recaptcha";
import DialogBox from "../../../components/DialogBox/DialogBox";
import avatarImg from "./Assets/avatarImg.png";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { AddUser, Contact } from "../../../API/API";
import { store } from "../../../redux/redux";
import { ADD_USER_INFO } from "../../../redux/action";
import validator from "validator";
import { context } from "../../../Context/context";
import * as Scroll from "react-scroll";

let Element = Scroll.Element;

const Content = ({ onClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailValidation, setemailValidation] = useState(true);
  const [invitees, setinvitees] = useState([]);
  const history = useHistory();

  return (
    <context.Consumer>
      {(context) => (
        <div className="panelContainer">
          <div style={{ padding: "1rem", alignSelf: "flex-end" }}>
            <button
              className="closeBTN"
              onClick={() => {
                onClose();
              }}
              style={{
                alignSelf: "flex-end",
                padding: "0.5rem",
              }}
            >
              <CloseIcon fontSize="small" style={{ color: "#013072" }} />
            </button>
          </div>
          <img style={{ width: "10%" }} src={avatarImg} />
          <div className="advice">
            *Use headphones to avoid echo in the experience
          </div>
          <input
            type="text"
            placeholder="Full Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="loginInput"
          />
          {!emailValidation && (
            <div className="mailError">*Enter Valid Email</div>
          )}
          <input
            type="email"
            placeholder="Email Address"
            onChange={(e) => {
              const email = e.target.value;
              setemailValidation(validator.isEmail(email));
              setEmail(email);
            }}
            className="loginInput"
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="LoginLine" style={{ textAlign: "center" }}>
              Invite friends
            </div>
            <input
              placeholder="Email Address"
              onChange={(e) => {
                let result = e.target.value.replace(/\s/g, "").split(/,|;/);
                const filteredResult = result.filter((e) => {
                  return e != "";
                });
                setinvitees([...filteredResult]);
              }}
              className="loginInput"
            />
            <div className="LoginLine" style={{ textAlign: "center" }}>
              *Seperate using commas
            </div>
          </div>

          <button
            type="submit"
            disabled={name == "" || email == ""}
            onClick={() => {
              if (emailValidation) {
                AddUser(email.toLowerCase(), name, invitees).then((e) => {
                  context.setAuth(true);
                  store.dispatch(ADD_USER_INFO(e));
                  history.push("/avatar");
                });
              }
            }}
            className="buttons"
          >
            ENTER METATRIBUTE
          </button>
        </div>
      )}
    </context.Consumer>
  );
};

const ThankYou = ({ text, onClose }) => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <button
          className="closeBTN"
          onClick={() => {
            onClose();
          }}
          style={{
            padding: "0.5rem",
          }}
        >
          <CloseIcon fontSize="small" style={{ color: "#013072" }} />
        </button>
      </div>
      <div
        style={{
          color: "#013072",
          fontFamily: "Heebo",
          fontWeight: 600,
        }}
      >
        {text}
      </div>
    </div>
  );
};
function LandingPage() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [verified, setVerified] = useState(false);
  const [queryMail, setqueryMail] = useState("");
  const [queryName, setqueryName] = useState("");
  const [queryMessage, setqueryMessage] = useState("");
  const [queryCheck, setqueryCheck] = useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [feedbackEmailValidate, setfeedbackEmailValidate] = useState(true);

  useEffect(() => {
    window.onpopstate = () => {
      window.location.reload();
    };
  }, []);

  const onClose = () => {
    setDialogOpen(false);
  };

  return (
    <Element>
      <DialogBox Content={dialogContent} open={dialogOpen} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="ToolBar">
          <img className="Logo" style={{ marginLeft: "2%" }} src={XRCLogo} />
        </div>
        <div className="imgContainer">
          <div className="LineContainer">
            <img
              className="Pattern"
              style={{
                width: "85%",
                marginLeft: "-18%",
                marginTop: "-50%",
                zIndex: -99,
              }}
              src={Pattern}
            />
            <div
              className="Lines"
              style={{
                marginTop: "-50%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="Line1">A #MetaTribute to</div>
              <div className="Line2">THE NIGHTINGALE OF INDIA</div>
              <div className="Line3">
                Experience Lata Ji's magnificent music once again from various
                era's throughout her life in an exclusive #MetaMelodies powered
                by #MetaQube™.
              </div>
              <button
                className="buttons"
                onClick={() => {
                  setDialogOpen(true);
                  setdialogContent(<Content onClose={onClose} />);
                }}
              >
                LET’S BEGIN
              </button>
            </div>
          </div>
          <div
            style={{
              width: "40%",
              height: "40%",
              marginRight: "10%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img className="LataLogo" src={LataImage} />
            <img
              className="Pattern"
              style={{
                height: "100%",
                width: "100%",
                marginLeft: "35%",
                marginTop: "-60%",
              }}
              src={Pattern}
            />
          </div>
        </div>
        <div className="smallPanel">
          <div className="panelHolder">
            <div className="panel_Container">
              <img className="Logo" style={{ width: "40%" }} src={gramoPhone} />
              <div className="panle_Container_Text">
                Listen to her music through the decades
              </div>
            </div>
            <div className="panel_Container">
              <img className="Logo" style={{ width: "20%" }} src={Mic} />
              <div className="panle_Container_Text">Follow her journey</div>
            </div>
            <div className="panel_Container">
              <img className="Logo" style={{ width: "50%" }} src={Medal} />
              <div className="panle_Container_Text">
                Discover her achievements
              </div>
            </div>
          </div>
        </div>
        <img
          src={Pattern}
          style={{
            alignSelf: "center",
            height: "50%",
            width: "50%",
            marginLeft: "-100%",
            marginTop: "-10%",
            opacity: 0.3,
          }}
        />
        <div
          style={{
            backgroundColor: "#013072",
            color: "white",
            width: "100%",
            marginTop: "-25%",
          }}
        >
          <div className="inputHolder">
            <div className="getinTouchHeading">Get in Touch</div>
            <input
              placeholder="Name"
              type="text"
              onChange={(e) => {
                setqueryName(e.target.value);
              }}
              className="feedbackInput"
            />
            {!feedbackEmailValidate && (
              <label className="feedbackMailErr">*Invalid Email</label>
            )}
            <input
              placeholder="Email"
              type="text"
              cols="40"
              rows="5"
              className="feedbackInput"
              onChange={(e) => {
                const email = e.target.value;
                setfeedbackEmailValidate(validator.isEmail(email));
                setqueryMail(email);
              }}
            />
            <textarea
              placeholder="Message"
              type="text"
              cols="40"
              rows="5"
              className="feedbackInput"
              onChange={(e) => {
                setqueryMessage(e.target.value);
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  setqueryCheck(e.target.value);
                }}
              />
              <div className="lastLine">
                Send me the latest updates on upcoming #MetaQube™ Experiences.
              </div>
            </div>
            <div>
              <ReCAPTCHA
                onChange={() => {
                  setVerified(true);
                }}
                onExpired={() => {
                  setVerified(false);
                }}
                sitekey="6LcRq4AeAAAAAI3kaB4kltqlOvuVac4v3iEtYUWi"
              />
            </div>
            <button
              onClick={() => {
                Contact(
                  queryMail.toLowerCase(),
                  queryName,
                  queryMessage,
                  queryCheck
                ).then((e) => {
                  setDialogOpen(true);
                  setdialogContent(
                    <ThankYou
                      onClose={() => {
                        onClose();
                        window.location.reload();
                      }}
                      text={"Thank You for Contacting Us!!"}
                    />
                  );
                });
              }}
              className="SendButton"
              disabled={!verified || !feedbackEmailValidate}
            >
              SEND
            </button>
          </div>
          <div
            style={{ wordWrap: "normal", margin: "1rem", fontSize: "0.6rem" }}
          >
            *Disclaimer: Please note no copyright infringement is intended. XR
            Central neither owns nor claims any songs and content utilised in
            the experience. This is a non-profit project. The experience may
            contain copyrighted material, the use of which has not always been
            specifically authorised by the copyright owner. No monetary gains or
            profits have been received through this project and nor is the
            intend.
          </div>
        </div>
        <img
          src={BottomPattern}
          className="test"
          style={{
            alignSelf: "center",
            width: "25%",
            marginRight: "-75%",
            marginTop: "-25.5%",
            opacity: 0.3,
          }}
        />
      </div>
    </Element>
  );
}

export default LandingPage;
