import * as BABYLON from "babylonjs";
import { configModel } from "./MaterialConfigurator";
import { user } from "./Player";
import { createSongsUi } from "./songsUiManager";
import { store } from "../../../redux/redux";
import { createNotesUi } from "./notesGUI";
import { showSlideShow } from "./slideShow";

//music notes
import DO from "../assets/music Notes/DO.mp3";
import FA from "../assets/music Notes/FA.mp3";
import LA from "../assets/music Notes/LA.mp3";
import MI from "../assets/music Notes/MI.mp3";
import RE from "../assets/music Notes/RE.mp3";
import SI from "../assets/music Notes/SI.mp3";
import SOL from "../assets/music Notes/SOL.mp3";

const musicNotesArray = [DO, FA, LA, MI, RE, SI, SOL];
export let loadedMusic = {};

export let reticle = null;

const images = [
  "/slideShow/Main-Screen.jpg",
  "/slideShow/Main-Screen2.jpg",
  "/slideShow/Main-Screen3.jpg",
];

export const enableNavigation = (assetManager, model, scene, reticleTex) => {
  const envTask = assetManager.addMeshTask("env Task", "", model, "");
  musicNotesArray.forEach((musicNote, index) => {
    const musictask = assetManager.addBinaryFileTask(index, musicNote, "");
    musictask.onSuccess = (task) => {
      loadedMusic[task.name] = task.data;
    };
  });
  envTask.onSuccess = (meshes) => {
    const mainModel = meshes.loadedMeshes[0];
    showSlideShow(scene.getMeshByName("Front Screen"), images, 8000, scene);

    // set Materials properties from json
    configModel(scene);

    // set songs ui
    createSongsUi(scene);
    createNotesUi(scene);

    for (let index = 0; index < mainModel._children.length; index++) {
      const element = mainModel._children[index];
      if (element.name === "navmesh") {
        element.enablePointerMoveEvents = true;
        element.metadata.tag = "navigationFloor";
      } else if (
        element.name === "Animated Light_01" ||
        element.name === "Animated Light_02"
      ) {
        element._children[0]._children.forEach((element) => {
          element.isPickable = false;
        });
      } else if (element.name === "collider") {
        element.isPickable = false;
        element.collisionsEnabled = true;
        element._meshCollisionData._checkCollisions = true;
      }
    }

    scene.executeWhenReady(() => {
      for (let index = 0; index < scene.animationGroups.length; index++) {
        const element = scene.animationGroups[index];
        element.play();
        element.loopAnimation = true;
      }

      //door
      scene.getMeshByName("Door_01_primitive0").isPickable = false;
      scene.getMeshByName("Door_02_primitive0").isPickable = false;
      const triggerSphere = new BABYLON.MeshBuilder.CreateSphere(
        "triggerSphere",
        { diameterX: 20, diameterY: 2, diameterZ: 20 },
        scene
      );
      triggerSphere.position = new BABYLON.Vector3(0.07, 0.5, 7);
      triggerSphere.isPickable = false;
      triggerSphere.isVisible = false;

      const user = store.getState().userData.user_id;
      const userName = store.getState().userData.user_name;

      //doorOpen Action
      const localPlayerMesh = scene.getMeshByName(user);
      const leftDoor = scene.getTransformNodeByName("Door_01");
      const rightDoor = scene.getTransformNodeByName("Door_02");

      scene.registerBeforeRender(function () {
        if (localPlayerMesh.intersectsMesh(triggerSphere, true)) {
          leftDoor.position.x = BABYLON.Scalar.Lerp(
            leftDoor.position.x,
            -1.6,
            0.025
          );
          rightDoor.position.x = BABYLON.Scalar.Lerp(
            rightDoor.position.x,
            1.6,
            0.025
          );
        } else {
          leftDoor.position.x = BABYLON.Scalar.Lerp(
            leftDoor.position.x,
            0,
            0.025
          );
          rightDoor.position.x = BABYLON.Scalar.Lerp(
            rightDoor.position.x,
            0,
            0.025
          );
        }
      });
    });
  };

  const textureTask = assetManager.addTextureTask("reticle", reticleTex, "");

  textureTask.onSuccess = (task) => {
    reticle = new BABYLON.MeshBuilder.CreateDisc(
      "reticle",
      {
        radius: 0.2,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      },
      scene
    );
    reticle.isPickable = false;
    reticle.rotation.set(Math.PI / 2, 0, 0);
    const reticleMat = new BABYLON.StandardMaterial("reticleMat", scene);
    reticleMat.diffuseTexture = task.texture;
    reticleMat.emissiveTexture = task.texture;
    reticleMat.opacityTexture = task.texture;
    reticle.material = reticleMat;
  };
};

export const pointerEvents = (scene, camera, reticle) => {
  scene.onPointerObservable.add((pointerInfo) => {
    switch (pointerInfo.type) {
      case BABYLON.PointerEventTypes.POINTERMOVE:
        if (pointerInfo.pickInfo.pickedMesh) {
          switch (pointerInfo.pickInfo.pickedMesh.metadata.tag) {
            case "navigationFloor": {
              if (reticle) {
                reticle.isVisible = true;
                reticle.position = new BABYLON.Vector3(
                  pointerInfo.pickInfo.pickedPoint.x,
                  pointerInfo.pickInfo.pickedPoint.y + 0.1,
                  pointerInfo.pickInfo.pickedPoint.z
                );
              }
              break;
            }
          }
          break;
        }

      case BABYLON.PointerEventTypes.POINTERTAP:
        if (pointerInfo.pickInfo.pickedMesh) {
          switch (pointerInfo.pickInfo.pickedMesh.metadata.tag) {
            case "navigationFloor": {
              const targetLocation = new BABYLON.Vector3(
                pointerInfo.pickInfo.pickedPoint.x,
                user.position.y,
                pointerInfo.pickInfo.pickedPoint.z
              );
              var cameraForwardRayPosition = camera.getForwardRay().direction;
              var cameraForwardRayPositionWithoutY = new BABYLON.Vector3(
                cameraForwardRayPosition.x,
                0,
                cameraForwardRayPosition.z
              );
              user._children[0].lookAt(
                user._children[0].position.add(
                  cameraForwardRayPositionWithoutY
                ),
                0,
                0,
                0
              );

              BABYLON.Animation.CreateAndStartAnimation(
                "Movement",
                user,
                "position",
                60,
                150,
                user.position,
                targetLocation,
                BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
                undefined
              );
              break;
            }
          }
          break;
        }
    }
  });
};
