import * as BABYLON from "babylonjs";
import * as GUI from "babylonjs-gui";

import { beginParticleSystem } from "./particlesManager";

import songsPanelBG from "../assets/Beige-playlist-panels.jpg";

import playButImg from "../assets/play.png";
import pauseButImg from "../assets/pause.png";
import ringButImg from "../assets/buttonRing.png";
import { socket, updateCurrentSong } from "./Multiplayer/Network";
import { store } from "../../../redux/redux";

let currentMusic = { element: null, audioComp: null, anim: null };

export function createSongsUi(scene) {
  GUI.Control.prototype.getScene = function () {
    return scene;
  };

  const particleSys = beginParticleSystem(scene);

  fetch("/jsonfiles/songsDatabase.json")
    .then((response) => response.json())
    .then((songsData) => {
      songsData.SongsEra.forEach((db_era) => {
        const uiMesh = scene.getMeshByName(db_era.era + "s Songs");

        const uiAdvanceTex = new GUI.AdvancedDynamicTexture.CreateForMesh(
          uiMesh,
          600,
          1024
        );
        uiMesh.material.backFaceCulling = true;
        const panel = new GUI.Image();
        panel.source = songsPanelBG;
        uiAdvanceTex.addControl(panel);
        //heading
        const heading = new GUI.TextBlock("Heading");
        heading.color = "#223D79";
        heading.fontSize = 50;
        heading.verticalAlignment = 0;
        heading.textHorizontalAlignment = 0;
        heading.textWrapping = 1;
        heading.left = "20px";
        heading.top = "100px";
        heading.width = "80%";
        heading.height = "14%";
        heading.text = `LATA JI'S SONGS FROM THE ${db_era.era}'S`;
        uiAdvanceTex.addControl(heading);

        //stackPanel
        const stack_Panel = new GUI.StackPanel("songsStackPanel");
        stack_Panel.verticalAlignment = 1;
        stack_Panel.width = "100%";
        stack_Panel.height = "75%";
        stack_Panel.scaleX = 0.8;
        uiAdvanceTex.addControl(stack_Panel);

        //Adding Stack Element
        Object.keys(db_era.songsList).forEach((songObj) => {
          const songPanel = new GUI.Rectangle();
          songPanel.verticalAlignment = 0;
          songPanel.width = "100%";
          songPanel.height = "80px";
          songPanel.thickness = 0;
          stack_Panel.addControl(songPanel);

          const songName = new GUI.TextBlock("songName");
          songName.text = songObj;
          songPanel.addControl(songName);
          songName.textHorizontalAlignment = 0;
          songName.left = "50px";
          songName.width = "530px";
          songName.height = "75px";
          songName.fontSize = 40;
          songName.color = "#223D79";
          songName.textWrapping = 2;

          const playButton = new GUI.Button.CreateImageOnlyButton(
            "playButton",
            ringButImg
          );
          songPanel.addControl(playButton);
          playButton.thickness = 0;
          playButton.width = "70px";
          playButton.height = "70px";
          playButton.left = -260;

          const butImg = new GUI.Image("playBut");
          butImg.source = playButImg;
          playButton.addControl(butImg);
          butImg.thickness = 0;
          butImg.width = "80%";
          butImg.height = "80%";

          playButton.pointerEnterAnimation = () => {
            playButton.width = "80px";
            playButton.height = "80px";
          };

          playButton.pointerOutAnimation = () => {
            playButton.width = "70px";
            playButton.height = "70px";
          };

          playButton.onPointerClickObservable.add(() => {
            const id = store.getState().userData.user_id;
            if (currentMusic.audioComp != null) {
              if (currentMusic.audioComp.name == songObj) {
                if (currentMusic.audioComp.isPlaying) {
                  currentMusic.audioComp.pause();
                  particleSys.stop();
                  butImg.source = playButImg;
                  currentMusic.anim.pause();
                  updateCurrentSong(id, "");
                  syncMusicPlayed("");
                } else {
                  currentMusic.audioComp.play();
                  particleSys.reset();
                  particleSys.start(500);
                  currentMusic.anim.restart();
                  butImg.source = pauseButImg;
                }
              } else {
                currentMusic.element.children[1].source = playButImg;
                currentMusic.anim.stop();
                particleSys.stop();
                currentMusic.audioComp.dispose();

                playSong();
                const particlePos = scene.getMeshByName(
                  `Gramophone_${db_era.era}s`
                ).position;
                particleSys.emitter = scene.getMeshByName(
                  `Gramophone_${db_era.era}s`
                );
                particleSys.reset();
                particleSys.start(500);
                animateRing();
              }
            } else {
              playSong();
              const particlePos = scene.getMeshByName(
                `Gramophone_${db_era.era}s`
              ).position;
              particleSys.emitter = scene.getMeshByName(
                `Gramophone_${db_era.era}s`
              );
              particleSys.reset();
              particleSys.start(500);
              animateRing();
            }

            //playSong
            function playSong() {
              currentMusic.audioComp = new BABYLON.Sound(
                songObj,
                db_era.songsList[songObj],
                scene,
                null,
                {
                  autoplay: true,
                  spatialSound: true,
                  maxDistance: 12,
                  distanceModel: "linear",
                  rolloffFactor: 1,
                }
              );
              //update namePlate
              updateCurrentSong(id, songObj);
              syncMusicPlayed(songObj);
              currentMusic.audioComp.onended = () => {
                butImg.source = playButImg;
                currentMusic.anim.stop();
                particleSys.stop();
                updateCurrentSong(id, "");
                syncMusicPlayed("");
              };
              particleSys.start(500);
              currentMusic.element = playButton;
              butImg.source = pauseButImg;

              //attach to mesh
              currentMusic.audioComp.attachToMesh(
                scene.getMeshByName(`Gramophone_${db_era.era}s`)
              );
            }

            //animate
            function animateRing() {
              currentMusic.anim = BABYLON.Animation.CreateAndStartAnimation(
                "test",
                playButton.children[0],
                "rotation",
                30,
                25,
                playButton.children[0].rotation,
                6.28,
                BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
              );
            }
          });
        });
      });
    });
}

function syncMusicPlayed(songName) {
  socket.emit("musicPlayed", {
    userId: store.getState().userData.user_id,
    room: store.getState().userData.room_id,
    songName: songName,
  });
}
