import * as BABYLON from "babylonjs";

export const configModel = (scene) => {
  const userAgent = window.navigator.userAgent;
  if (!userAgent.includes("iPhone OS 15_4")) {
    const glowLayer = new BABYLON.GlowLayer("glowLayer", scene);
    glowLayer.intensity = 1;
    glowLayer.addIncludedOnlyMesh(scene.getMeshByName("Song_01"));
    glowLayer.addIncludedOnlyMesh(scene.getMeshByName("Center_Light_Emission"));
  }

  fetch("/jsonfiles/MaterialDatabase.json")
    .then((response) => response.json())
    .then((matsdata) => {
      matsdata.materialsConfig.forEach((matConfig) => {
        const targetMat = scene.getMaterialByName(matConfig.materialName);
        Object.keys(matConfig).forEach((matProperty) => {
          if (matProperty.includes("Texture")) {
            if (matConfig[matProperty].sourceUrl.includes("mp4")) {
              targetMat[matProperty] = new BABYLON.VideoTexture(
                "",
                window.location.origin + matConfig[matProperty].sourceUrl,
                scene,
                false,
                matConfig[matProperty].invertY != undefined
                  ? matConfig[matProperty].invertY
                  : true,
                BABYLON.VideoTexture.TRILINEAR_SAMPLINGMODE,
                {
                  loop: true,
                  autoPlay: true,
                  autoUpdateTexture: true,
                  muted: true,
                }
              );
            } else {
              targetMat[matProperty] = new BABYLON.Texture(
                window.location.origin + matConfig[matProperty].sourceUrl,
                scene,
                false,
                matConfig[matProperty].invertY != undefined
                  ? matConfig[matProperty].invertY
                  : true
              );
            }

            targetMat[matProperty].onLoadObservable.addOnce(() => {
              Object.keys(matConfig[matProperty]).forEach((texProperties) => {
                //check if this tex property is a getter
                if (
                  Object.getOwnPropertyDescriptor(
                    targetMat[matProperty],
                    texProperties
                  )
                ) {
                  targetMat[matProperty][texProperties] =
                    matConfig[matProperty][texProperties];
                }
              });
            });
          }
          //setColor
          else if (matProperty.includes("Color")) {
            targetMat[matProperty] = BABYLON.Color3.FromHexString(
              matConfig[matProperty]
            ).toLinearSpace();
          } else {
            targetMat[matProperty] = matConfig[matProperty];
          }
        });
      });
    });
};
