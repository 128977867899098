import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import loadingScreenBG from "../../Containers/experience/assets/android-chrome-512x512.png";
import "./LoadingScreen.css";

export default function LoadingScreen(props) {
  const { progress } = props;
  return (
    <div className="loadingRoot">
      <img
        className="PatternImg1"
        src="https://xrcentralprojectspublic.s3.ap-south-1.amazonaws.com/LataMangeshkar/LoadingData/TL(Pattern).png"
      />
      <div className="MiddleContainer">
        <img
          className="LogoImg"
          src="https://xrcentralprojectspublic.s3.ap-south-1.amazonaws.com/LataMangeshkar/LoadingData/LataJILogo.png"
        />

        <div
          style={{ fontFamily: "Heebo", color: "#203E7E", fontSize: "medium" }}
        >
          Preparing your #Metamelodies experience...
        </div>
        <CircularProgress
          variant="determinate"
          value={progress}
          style={{ color: "black" }}
        />
        <div className="Middle_InnerConatiner">
          <div
            style={{
              fontFamily: "Heebo",
              color: "#203E7E",
              fontSize: "small",
            }}
          >
            Powered by
          </div>
          <img
            style={{ width: "15%" }}
            src="https://xrcentralprojectspublic.s3.ap-south-1.amazonaws.com/LataMangeshkar/LoadingData/XRC_Logo.png"
          />
        </div>
      </div>

      <img
        className="PatternImg2"
        src="https://xrcentralprojectspublic.s3.ap-south-1.amazonaws.com/LataMangeshkar/LoadingData/BR(Pattern).png"
      />
    </div>
  );
}
