import AgoraRTC from "agora-rtc-sdk-ng";
import {
  addAgoraAudio,
  addAgoraVideo,
  disableVideo,
  setAudioVolumeIndicator,
  setMicStatus,
} from "../Containers/experience/scene/Multiplayer/Network";
import {
  REMOTE_USER,
  REMOTE_USER_LEFT,
  UPDATE_LOCAL_AGORA_DATA,
} from "../redux/action";
import { store } from "../redux/redux";
import { generateRtcToken } from "./agoraToken";

const APP_ID = "bba9e4f54cde41e0bcab9aeb2866c6ae";
let TOKEN = null;
let CHANNEL = null;
let uid = null;
let localTracks = { audioTrack: "", videoTrack: "" };

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
let container = null;

AgoraRTC.setLogLevel(3);
const handleErr = (err) => {
  console.log(err);
};

export const joinCall = async () => {
  container = document.getElementById("container");
  uid = store.getState().userData.user_id;
  CHANNEL = store.getState().userData.room_id;
  TOKEN = generateRtcToken(CHANNEL, uid);
  client.enableAudioVolumeIndicator();
  client.on("user-published", handleUserjoined);
  client.on("user-left", handleUserLeft);
  client.on("user-unpublished", handleUnpublished);
  client.on("volume-indicator", handleAudioIndicator);
  AgoraRTC.onMicrophoneChanged = (info) => {
    AgoraRTC.getMicrophones().then((e) => {
      localTracks.audioTrack
        .setDevice(e[0].deviceId)
        .then(() => {
          console.log("set device success");
        })
        .catch((e) => {
          console.log("set device error", e);
        });
    });
  };

  await client.join(APP_ID, CHANNEL, TOKEN, uid).catch(handleErr);
  AgoraRTC.createMicrophoneAudioTrack({ AEC: true, ANS: true })
    .then(async (e) => {
      localTracks.audioTrack = e;
      store.dispatch(
        UPDATE_LOCAL_AGORA_DATA({
          audioMuted: false,
          audioTrack: e,
          MicPermission: true,
        })
      );
      await client.publish(e).then(async () => {
        await toggleAudio();
      });
    })
    .catch((e) => {
      store.dispatch(
        UPDATE_LOCAL_AGORA_DATA({
          audioMuted: true,
          audioTrack: undefined,
          MicPermission: false,
        })
      );
    });
  AgoraRTC.createCameraVideoTrack()
    .then(async (e) => {
      e.play(document.getElementById("video"));
      localTracks.videoTrack = e;
      store.dispatch(
        UPDATE_LOCAL_AGORA_DATA({
          videoMuted: false,
          videoTrack: e,
          CamPermission: true,
        })
      );
      await client.publish(e).then(async () => {
        // await toggleVideo();
        await localTracks.videoTrack.setEnabled(false);
        store.dispatch(
          UPDATE_LOCAL_AGORA_DATA({
            videoMuted: true,
          })
        );
      });
    })
    .catch((e) => {
      store.dispatch(
        UPDATE_LOCAL_AGORA_DATA({
          videoMuted: true,
          videoTrack: undefined,
          CamPermission: false,
        })
      );
    });
};

const handleUserjoined = async (user, mediatype) => {
  await client.subscribe(user, mediatype);
  if (user.uid != uid) {
    if (mediatype == "video") {
      const temp = document.createElement("div");
      temp.id = user.uid;
      container.append(temp);
      user?.videoTrack?.play(temp);
      store.dispatch(
        REMOTE_USER({
          userID: user.uid,
          videoTrack: user.videoTrack,
          videoMuted: false,
        })
      );
      addAgoraVideo(user.uid, user?.videoTrack);
    } else if (mediatype == "audio") {
      store.dispatch(
        REMOTE_USER({
          userID: user.uid,
          audioTrack: user.audioTrack,
          audioMuted: false,
        })
      );
      // user.audioTrack.play();
      addAgoraAudio(user.uid, user.audioTrack);
    }
  }
};

const handleUnpublished = (user, mediaType) => {
  if (user.uid != uid) {
    if (mediaType == "video") {
      document.getElementById(user.uid)?.remove();
      store.dispatch(
        REMOTE_USER({
          userID: user.uid,
          videoMuted: true,
        })
      );
      disableVideo(user.uid);
    } else {
      store.dispatch(
        REMOTE_USER({
          userID: user.uid,
          audioMuted: true,
        })
      );

      setMicStatus(user.uid, false);
    }
  }
};

const handleUserLeft = (user) => {
  if (user.uid != uid) {
    store.dispatch(REMOTE_USER_LEFT({ id: user.uid }));
  }
};

const handleAudioIndicator = (volumes) => {
  volumes.forEach((volume) => {
    if (store.getState().remoteUsers[volume.uid]?.audioMuted) {
      return;
    }
    if (volume.level > 12) {
      setAudioVolumeIndicator(volume.uid, true);
    } else {
      setAudioVolumeIndicator(volume.uid, false);
    }
  });
};

async function toggleAudio() {
  if (store.getState().localAgoraData.MicPermission) {
    if (localTracks.audioTrack.enabled) {
      await localTracks.audioTrack.setEnabled(false);
      // setMicStatus(store.getState().userData.user_id, false);
    } else {
      await localTracks.audioTrack.setEnabled(true);
      // setMicStatus(store.getState().userData.user_id, true);
    }
    store.dispatch(
      UPDATE_LOCAL_AGORA_DATA({
        audioMuted: !localTracks.audioTrack.enabled,
      })
    );
  }
}

const toggleVideo = async () => {
  if (store.getState().localAgoraData.CamPermission) {
    if (localTracks.videoTrack.enabled) {
      await localTracks.videoTrack.setEnabled(false);
    } else {
      await localTracks.videoTrack.setEnabled(true);
    }
    store.dispatch(
      UPDATE_LOCAL_AGORA_DATA({
        videoMuted: !localTracks.videoTrack.enabled,
      })
    );
  }
};

const leaveCall = async () => {
  await client.leave();
};

export { toggleAudio, toggleVideo, leaveCall };
