import * as BABYLON from "babylonjs";

import songNote from "../assets/musicNote.png";

let ps = null;

export function beginParticleSystem(scene) {
  const particleSystem = new BABYLON.ParticleSystem("particles", 1000, scene);
  particleSystem.particleEmitterType = new BABYLON.ConeParticleEmitter(
    0.1,
    Math.PI / 4,
    1
  );
  particleSystem.emitRate = 8;
  particleSystem.minSize = 0.3;
  particleSystem.maxSize = 0.3;
  particleSystem.updateSpeed = 0.003;
  particleSystem.isLocal = true;
  particleSystem.minLifeTime = 0.3;
  particleSystem.maxLifeTime = 0.5;
  particleSystem.particleTexture = new BABYLON.Texture(songNote, scene);
  particleSystem.blendMode = BABYLON.ParticleSystem.BLENDMODE_MULTIPLYADD;

  particleSystem.addSizeGradient(0, 0.1, 0.1);
  particleSystem.addSizeGradient(0.05, 0.2, 0.2);
  particleSystem.addSizeGradient(1, 0.3, 0.3);

  return particleSystem;
}
