import React, { useEffect, useState } from "react";
import "./ExperienceUI.css";
import LoadingScreen from "../../../components/Loading/LoadingScreen";
import EraBar from "./EraBar/EraBar";
import DialogBox from "../../../components/DialogBox/DialogBox";
import TutorialImage from "./Assets/Tutorial.png";
import { SongsData } from "../scene/Database";
import Share from "./Share/Share";
import ShareIcon from "@material-ui/icons/Share";
import cameraIcon from "./EraBar/Assets/switch-camera.png";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import { toggleAudio, toggleVideo } from "../../../agora/agora";
import { store } from "../../../redux/redux";
import { socket } from "../scene/Multiplayer/Network";
import { useSnackbar } from "notistack";

const temp = (onClose) => (
  <div className="imgContainer">
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <img className="tutImage" src={TutorialImage} />
    </div>
    <button
      className="closeBTN EUIBTN"
      onClick={() => {
        onClose();
      }}
    >
      <CloseIcon fontSize="small" style={{ color: "#fff" }} />
    </button>
  </div>
);

function ExperienceUI({
  percent,
  moveToLocation,
  IncreaseEra,
  DecreaseEra,
  selected,
  setselected,
  switchCam,
  perspectiveChange,
  setPerspectiveChange,
  enterExperience,
}) {
  const [openTut, setopenTut] = useState(true);
  const [openShare, setopenShare] = useState(false);
  const [videoMuted, setvideoMuted] = useState(false);
  const [audioMuted, setaudioMuted] = useState(false);
  const [entryAnim, setentryAnim] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetch("/jsonfiles/Database.json")
      .then((e) => e.json())
      .then(async (data) => {
        Object.assign(SongsData, data);
      });
  }, []);
  useEffect(() => {
    const storeRef = store.subscribe(() => {
      const localUserData = store.getState().localAgoraData;
      setaudioMuted(localUserData.audioMuted);
      setvideoMuted(localUserData.videoMuted);
    });
    return () => {
      storeRef();
    };
  }, []);
  useEffect(() => {
    socket.on("newuserjoined", ({ user, userId, name, avatarId }) => {
      if (!openTut) {
        enqueueSnackbar(name + " has joined!", { variant: "success" });
      }
    });
  }, []);

  return (
    <div>
      {percent !== 100 ? (
        <LoadingScreen progress={percent} />
      ) : (
        <div className="container">
          <DialogBox
            open={openTut}
            Content={temp(() => {
              setopenTut(false);
            })}
            maxWidth="md"
            closeIconColor="#000"
            backgroundColor="rgba(1, 48, 114,0.8)"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              position: "fixed",
              top: 0,
              right: 0,
              marginRight: "2%",
              marginTop: "2%",
            }}
          >
            <IconButton
              onClick={() => {
                setopenShare(true);
              }}
              style={{
                color: "white",
                backgroundColor: "#013072",
                opacity: 0.8,
                backdropFilter: "saturate(180%) blur(2px)",
              }}
            >
              <ShareIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                switchCam();
                setPerspectiveChange(!perspectiveChange);
                switchCam();
              }}
              style={{
                color: "white",
                backgroundColor: "#013072",
                opacity: 0.8,
                backdropFilter: "saturate(180%) blur(2px)",
              }}
            >
              <img
                src={cameraIcon}
                style={{ width: "25px", height: "25px" }}
              ></img>
            </IconButton>
          </div>
          {!entryAnim && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.4)",
                backdropFilter: "blur(6px)",
                height: "100%",
                width: "100%",
                position: "fixed",
                zIndex: 99,
              }}
            >
              <button
                className="buttons"
                style={{
                  backdropFilter: "saturate(180%) blur(2px) ",
                  opacity: 0.8,
                }}
                onClick={() => {
                  enterExperience();
                  setentryAnim(true);
                }}
              >
                ENTER EXPERIENCE
              </button>
            </div>
          )}
          <div className="innerContainer">
            <div
              id="video"
              style={{ display: videoMuted ? "none" : "block" }}
            ></div>
            {/* {videoMuted && (
              <div className="videoCamIcon">
                <VideocamOffIcon fontSize="medium" />
              </div>
            )} */}
            <div
              style={{
                display: "flex",
                gap: 10,
                bottom: 0,
                position: "fixed",
                marginBottom: "2%",
                zIndex: 1,
                marginLeft: "2%",
              }}
            >
              <IconButton
                onClick={() => {
                  toggleAudio();
                }}
                style={{
                  color: "white",
                  backgroundColor: audioMuted ? "#fff" : "#013072",
                  opacity: 0.8,
                  backdropFilter: "saturate(180%) blur(2px)",
                }}
              >
                {audioMuted ? (
                  <MicOffIcon style={{ color: "#013072" }} />
                ) : (
                  <MicIcon style={{ color: "#fff" }} />
                )}
              </IconButton>
              <IconButton
                onClick={() => {
                  toggleVideo();
                }}
                style={{
                  color: "white",
                  backgroundColor: videoMuted ? "#fff" : "#013072",
                  opacity: 0.8,
                  backdropFilter: "saturate(180%) blur(2px)",
                }}
              >
                {videoMuted ? (
                  <VideocamOffIcon style={{ color: "#013072" }} />
                ) : (
                  <VideocamIcon style={{ color: "#fff" }} />
                )}
              </IconButton>
            </div>
            {entryAnim && (
              <EraBar
                moveToLocation={moveToLocation}
                IncreaseEra={IncreaseEra}
                DecreaseEra={DecreaseEra}
                selected={selected}
                setselected={setselected}
              />
            )}
          </div>

          <DialogBox
            open={openShare}
            backgroundColor={"rgba(1, 48, 114,0.8)"}
            Content={
              <Share
                onClose={() => {
                  setopenShare(false);
                }}
              />
            }
            closeIconColor={"fff"}
          />
        </div>
      )}
    </div>
  );
}

export default ExperienceUI;
