import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import AvatarSelection from "../components/Avatar/AvatarScreen";
import Studio from "../Containers/experience/Experience";
import LandingPage from "../Containers/experience/LandingPage/LandingPage";
import { context } from "../Context/context";
import Privateroutes from "./PrivateRoutes";

function RoutesComponent() {
  const [auth, setAuth] = useState(false);

  return (
    <context.Provider
      value={{
        setAuth: (param) => {
          setAuth(param);
        },
      }}
    >
      <BrowserRouter>
        <Route exact path="/" component={LandingPage}></Route>
        <Privateroutes
          isAuth={auth}
          exact
          path="/avatar"
          component={AvatarSelection}
        />
        <Privateroutes
          isAuth={auth}
          exact
          path="/experience"
          component={Studio}
        />
      </BrowserRouter>
    </context.Provider>
  );
}

export default RoutesComponent;
