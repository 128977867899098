import * as BABYLON from "babylonjs";
import * as GUI from "babylonjs-gui";

import notesPanelBG from "../assets/Musical-Note-Screen.jpg";

import { loadedMusic } from "./Navigator";

const data = [
  {
    top: 20,
    left: 100,
  },
  {
    top: -60,
    left: 250,
  },
  {
    top: -100,
    left: 450,
  },
  {
    top: 0,
    left: 630,
  },
  {
    top: 50,
    left: 850,
  },
  {
    top: 130,
    left: 1050,
  },
  {
    top: 130,
    left: 1250,
  },
];

export function createNotesUi(scene) {
  GUI.Control.prototype.getScene = function () {
    return scene;
  };

  const tv_Frame = scene.getMeshByName("TV_Frame_01");

  const advTex = new GUI.AdvancedDynamicTexture.CreateForMesh(
    tv_Frame,
    1582,
    686
  );

  const BG = new GUI.Image("BG", notesPanelBG);
  advTex.addControl(BG);
  const panel = new GUI.Rectangle("panel");
  advTex.addControl(panel);

  for (let i = 0; i < 7; i++) {
    const noteBut = new GUI.Button.CreateImageOnlyButton(
      `notes_${i}`,
      `/notes/${i}.png`
    );
    panel.addControl(noteBut);
    noteBut.horizontalAlignment = 0;
    noteBut.width = "160px";
    noteBut.height = "180px";
    noteBut.top = `${data[i].top}`;
    noteBut.left = `${data[i].left}`;

    noteBut.pointerEnterAnimation = () => {
      noteBut.width = "240px";
      noteBut.height = "270px";
      const sound = new BABYLON.Sound(
        i.toString(),
        copyBuffer(loadedMusic[i]),
        scene,
        null,
        { autoplay: true, spatialSound: true, maxDistance: 12 }
      );
      sound.attachToMesh(tv_Frame);
    };

    noteBut.pointerOutAnimation = () => {
      noteBut.width = "160px";
      noteBut.height = "180px";
    };

    noteBut.onPointerClickObservable.add(() => {
      const sound = new BABYLON.Sound(
        i.toString(),
        copyBuffer(loadedMusic[i]),
        scene,
        null,
        { autoplay: true, spatialSound: true, maxDistance: 12 }
      );
      sound.attachToMesh(tv_Frame);
    });
  }
}

function copyBuffer(source) {
  var copy = new ArrayBuffer(source.byteLength);
  new Uint8Array(copy).set(new Uint8Array(source));
  return copy;
}
