import * as BABYLON from "babylonjs";
import { io } from "socket.io-client";
import { avatarURLs } from "../Database";
import "babylonjs-loaders";
import * as GUI from "babylonjs-gui";
import { addShadowToPlayer } from "../Player";
import { store } from "../../../../redux/redux";

import muteButImg from "../../assets/mute.png";
import unMuteButImg from "../../assets/Unmute.png";

const EndPoint = "https://websocket.metaqube.ai";

export const socket = io(EndPoint, {
  transports: ["websocket"],
  upgrade: false,
});

let AllUsers = [];
let currentScene = null;

export const addNamePlate = (name, parent, scene, isLocalUser) => {
  var groundWidth = 1;
  var groundHeight = 1;

  var namePlate = BABYLON.MeshBuilder.CreatePlane(
    "namePlate",
    {
      width: groundWidth,
      height: groundHeight,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    },
    scene
  );
  namePlate.isPickable = false;

  const children = parent.getChildTransformNodes();
  children.forEach((headRef) => {
    if (headRef.name === "mixamorig:HeadTop_End") {
      namePlate.setParent(headRef);
    }
  });
  namePlate.position = new BABYLON.Vector3(0, 30, 0);
  namePlate.rotation = new BABYLON.Vector3(0, Math.PI, Math.PI);
  namePlate.scaling = new BABYLON.Vector3(200, -75, 100);

  //Create dynamic texture
  var advanceTexture = new GUI.AdvancedDynamicTexture.CreateForMesh(
    namePlate,
    400,
    150,
    false,
    null,
    true
  );

  var panel = new GUI.Rectangle("name");
  panel.background = "#FFFFFF";
  panel.color = "#FFFFFF";
  panel.cornerRadius = 10;
  panel.height = "60%";
  panel.width = "78%";
  panel.clipChildren = false;
  panel.thickness = 4;
  panel.verticalAlignment = 0;
  panel.horizontalAlignment = 0;
  if (isLocalUser) {
    panel.horizontalAlignment = 2;
  }

  advanceTexture.addControl(panel);
  var textBlock = new GUI.TextBlock("name", name);
  textBlock.resizeToFit = true;
  textBlock.fontSize = "45px";
  textBlock.color = "#2B528A";
  textBlock.textWrapping = 2;
  textBlock.width = "90%";
  panel.addControl(textBlock);

  const songName = new GUI.TextBlock("songPlaying", "");
  songName.color = "#E47F07";
  songName.height = "50px";
  songName.fontSize = "40px";
  songName.verticalAlignment = 1;
  songName.textHorizontalAlignment = 0;
  songName.shadowColor = "#FDE1C1";
  songName.shadowOffsetX = 1;
  songName.shadowOffsetY = 1;
  songName.shadowBlur = 2;
  songName.resizeToFit = true;
  advanceTexture.addControl(songName);

  const muteEllipse = new GUI.Ellipse("MicStatus");
  if (isLocalUser) {
    muteEllipse.isVisible = false;
  }
  muteEllipse.width = "80px";
  muteEllipse.height = "80px";
  muteEllipse.horizontalAlignment = 1;
  muteEllipse.verticalAlignment = 0;
  muteEllipse.top = "7px";
  muteEllipse.color = "#FFFFFF";
  muteEllipse.thickness = 4;
  advanceTexture.addControl(muteEllipse);

  const muteImg = new GUI.Image("mute", muteButImg);
  muteImg.width = "100%";
  muteImg.height = "100%";
  muteEllipse.addControl(muteImg);

  namePlate.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
};

export function updateCurrentSong(uid, songName) {
  const children = currentScene.getMeshByName(uid)?.getChildMeshes();
  children.forEach((namePlate) => {
    if (namePlate.name === "namePlate") {
      const songTextBox =
        namePlate?.material.emissiveTexture.rootContainer.children[1];
      songTextBox.text = songName;
      songTextBox.onAfterDrawObservable.addOnce(() => {
        if (songTextBox.anim !== undefined) {
        } else {
          songTextBox["anim"] = BABYLON.Animation.CreateAndStartAnimation(
            "test",
            songTextBox,
            "leftInPixels",
            30,
            150,
            400,
            -songTextBox.widthInPixels - 200,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
          );
        }
        songTextBox.anim.restart();
      });
    }
  });
}

export const spawnRemotePlayer = async (userId, name, scene, avatarId) => {
  let ref = null;
  let URL = "";

  for (let index = 0; index < avatarURLs.length; index++) {
    const element = avatarURLs[index];
    if (element.id === avatarId) {
      URL = element.url;
    }
  }

  await BABYLON.SceneLoader.ImportMeshAsync("", URL, "", scene, null).then(
    (player) => {
      const user = player.meshes[0];
      user.isPickable = false;
      user.enableCollisions = true;
      user.name = userId;
      user._children[0].rotation = new BABYLON.Vector3(0, Math.PI, 0);
      addShadowToPlayer(user._children[0], scene);
      user.scalingDeterminant = 0.5;
      user.scaling = new BABYLON.Vector3(-1, 1, -1);
      const plane = user._children[0]._children[1];
      plane.setEnabled(false);
      plane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
      plane.name = userId + "_userVideo";
      plane.position.y = 2.5;
      plane.scaling = new BABYLON.Vector3(0.75, 1, 0.75);
      const mat = new BABYLON.StandardMaterial("planeMat", scene);
      plane.material = mat;
      mat.diffuseColor = new BABYLON.Color3.Black();
      plane.material.backFaceCulling = false;
      user.metadata = { soundComp: null, videoAdded: false };
      ref = user;
      addNamePlate(name, user, scene, false);
      addAgoraVideo(userId, store.getState().remoteUsers[userId]?.videoTrack);
      addAgoraAudio(userId, store.getState().remoteUsers[userId]?.audioTrack);
    }
  );
  return ref;
};

export const addAgoraVideo = (uid, ms) => {
  if (currentScene == null) {
    return;
  }
  const mesh = currentScene.getMeshByName(uid);
  if (ms != undefined && mesh != undefined) {
    if (!mesh.metadata.videoAdded) {
      mesh.metadata.videoAdded = true;
      const videoTexture = new BABYLON.VideoTexture(
        "VT" + uid,
        ms?._player?.videoElement,
        currentScene,
        true,
        true,
        BABYLON.VideoTexture.TRILINEAR_SAMPLINGMODE,
        { loop: true, autoPlay: true, autoUpdateTexture: true }
      );
      mesh._children[0]._children[1].material.emissiveTexture = videoTexture;
    } else {
      mesh._children[0]._children[1].material.emissiveTexture.video.srcObject =
        ms?._player?.videoElement.srcObject;
    }
    mesh._children[0]._children[1].material.emissiveTexture.video.onloadedmetadata =
      () => {
        mesh._children[0]._children[1].material.emissiveTexture.video.play();
      };
    mesh._children[0]._children[1].setEnabled(true);
  }
};

export const disableVideo = (uid) => {
  if (currentScene == null) {
    return;
  }
  const mesh = currentScene.getMeshByName(uid);
  if (mesh) {
    mesh._children[0]._children[1].setEnabled(false);
  }
};

export const addAgoraAudio = (uid, ms) => {
  if (currentScene == null) {
    return;
  }
  const mesh = currentScene.getMeshByName(uid);
  if (ms != undefined && mesh != undefined) {
    if (mesh.metadata.soundComp) {
      mesh.metadata.soundComp.dispose();
    }
    const sound = new BABYLON.Sound(
      uid,
      ms._source.sourceNode.mediaStream,
      currentScene,
      null,
      {
        autoplay: true,
        streaming: true,
        spatialSound: true,
        maxDistance: 12,
        distanceModel: "linear",
        rolloffFactor: 1,
      }
    );
    mesh.metadata.soundComp = sound;
    sound.attachToMesh(mesh);

    //mic status on on gui
    setMicStatus(uid, true);
  }
};

export function setAudioVolumeIndicator(uid, isSpeaking) {
  if (currentScene == null) {
    return;
  }
  const mesh = currentScene.getMeshByName(uid);
  if (mesh != undefined) {
    const children = mesh.getChildMeshes();
    children.forEach((namePlate) => {
      if (namePlate.name === "namePlate") {
        const namePlateRect =
          namePlate.material.emissiveTexture.rootContainer.children[0];
        if (isSpeaking) {
          namePlateRect.color = "#2B528A";
        } else {
          namePlateRect.color = "#FFFFFF";
        }
      }
    });
  }
}

export function setMicStatus(uid, micStatus) {
  if (currentScene == null) {
    return;
  }
  const mesh = currentScene.getMeshByName(uid);
  if (mesh != undefined) {
    const children = mesh.getChildMeshes();
    children.forEach((namePlate) => {
      if (namePlate.name === "namePlate") {
        const micEllipse =
          namePlate.material.emissiveTexture.rootContainer.children[2];
        const nameRect =
          namePlate.material.emissiveTexture.rootContainer.children[0];
        if (micStatus) {
          micEllipse.isVisible = false;
          nameRect.horizontalAlignment = 2;
          // micEllipse.children[0].source = unMuteButImg;
          // micEllipse.color = "#FFFFFF";
        } else {
          micEllipse.isVisible = true;
          nameRect.horizontalAlignment = 0;
          nameRect.color = "#FFFFFF";
          // micEllipse.children[0].source = muteButImg;
        }
      }
    });
  }
}

export const joinRoom = (camera, scene, avatarID) => {
  currentScene = scene;
  const user_ID = store.getState().userData.user_id;
  const user_Name = store.getState().userData.user_name;
  const room_ID = store.getState().userData.room_id;
  const session_id = store.getState().userData.session_id;
  console.log(session_id);
  currentScene = scene;

  socket.on("sendCurrentPos", () => {
    const user = scene.getMeshByName(user_ID);
    if (user) {
      const newPosition = {
        x: user.position.x,
        y: user.position.y,
        z: user.position.z,
      };
      const newRotation = {
        y: user._children[0].rotation.y,
      };
      socket.emit("updatePosition", {
        userId: user_ID,
        room: room_ID,
        newpos: newPosition,
        newRot: newRotation,
      });
    }
  });

  socket.emit(
    "join",
    {
      userId: user_ID,
      name: user_Name,
      room: room_ID,
      avatarId: avatarID,
      session_Id: session_id,
    },
    ({ id, users }) => {
      AllUsers = users;
      AllUsers.forEach((element) => {
        if (element.userId !== user_ID) {
          spawnRemotePlayer(
            element.userId,
            element.name,
            scene,
            element.avatarId
          ).then((remoteplayer) => {
            remoteplayer.position = new BABYLON.Vector3(-0.1, 1.4, 19.4);
            socket.emit("pingCurrentPos", { room_ID: room_ID });
          });
        }
      });

      const user = scene.getMeshByName(user_ID);

      if (user) {
        user.onAfterWorldMatrixUpdateObservable.add(() => {
          const newPosition = {
            x: user.position.x,
            y: user.position.y,
            z: user.position.z,
          };
          const newRotation = {
            y: user._children[0].rotation.y,
          };
          socket.emit("updatePosition", {
            userId: user_ID,
            room: room_ID,
            newpos: newPosition,
            newRot: newRotation,
          });
        });
      }
    }
  );

  socket.on("newuserjoined", ({ user, userId, name, avatarId }) => {
    spawnRemotePlayer(userId, name, scene, avatarId).then((remoteplayer) => {
      remoteplayer.position = new BABYLON.Vector3(-0.1, 1.4, 19.4);
    });
  });

  socket.on("userleft", ({ userId }) => {
    try {
      scene.getMeshByName(userId).dispose();
    } catch (err) {
      console.log("user does not exist");
    }
  });

  socket.on("remoteListeningStatus", ({ userId, songName }) => {
    updateCurrentSong(userId, songName);
  });

  socket.on("remoteUserPositionChange", ({ userId, changePos, changeRot }) => {
    const mesh = scene.getMeshByName(userId);
    if (mesh) {
      mesh.position = new BABYLON.Vector3(
        changePos.x,
        changePos.y,
        changePos.z
      );
      mesh.rotation = new BABYLON.Vector3(0, changeRot.y, 0);
    }
  });
};
export const localUserLeave = () => {
  socket.emit("leaveroom");
};

// export const onAnimPlayed = (animIndex) => {
//   socket.emit("animPlayed", {
//     userId: userID,
//     room: roomName,
//     animIndex: animIndex,
//   });
// };
