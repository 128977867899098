export const ADD_USER_INFO = (userData) => ({
  type: "ADD_USER_INFO",
  data: userData,
});
export const UPDATE_LOCAL_AGORA_DATA = (userData) => ({
  type: "UPDATE_LOCAL_AGORA_DATA",
  data: userData,
});
export const REMOTE_USER = (userData) => ({
  type: "REMOTE_USER",
  data: userData,
});
export const REMOTE_USER_LEFT = (userData) => ({
  type: "REMOTE_USER_LEFT",
  data: userData,
});
