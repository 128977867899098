export const SongsData = {};
export const avatarURLs = [
  {
    id: "0",
    image: "Models/03.png",
    url: "Models/Mail_Suit.glb",
  },
  {
    id: "1",
    image: "Models/02.png",
    url: "Models/Mail_Beard_01.glb",
  },
  {
    id: "2",
    image: "Models/01.png",
    url: "Models/Mail_Beard_02.glb",
  },
  {
    id: "3",
    image: "Models/05.png",
    url: "Models/Female_02.glb",
  },
  {
    id: "4",
    image: "Models/06.png",
    url: "Models/Female_03.glb",
  },
  {
    id: "5",
    image: "Models/04.png",
    url: "Models/Female_01.glb",
  },
];
