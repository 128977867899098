import React, { useEffect, useRef, useState } from "react";
import forwardImg from "./Assets/forward.png";
import backwardImg from "./Assets/backward.png";
import "./EraBar.css";
import { SongsData } from "../../scene/Database";

function EraBar({
  moveToLocation,
  IncreaseEra,
  DecreaseEra,
  selected,
  setselected,
}) {
  const [active, setActive] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div
      style={{
        fontFamily: "Roboto",
        height: "100%",
        display: "flex",
        alignItems: "flex-end",
        position: "fixed",
      }}
    >
      <div
        style={{
          width: "100%",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          // marginBottom: "2%",
        }}
      >
        {active && (
          <div ref={ref} className="listHolder">
            <ul key="unClickableList" className="unClickableList">
              Select Era
            </ul>
            {Object.keys(SongsData).map((e, index) => (
              <ul
                key={index}
                onClick={() => {
                  setselected(e);
                  setActive(false);
                  moveToLocation(
                    SongsData[e].position,
                    SongsData[e].rotation,
                    SongsData[e].alpha,
                    SongsData[e].beta
                  );
                }}
                className="list"
              >
                {e}
              </ul>
            ))}
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginRight: "2%",
            marginBottom: "2%",
          }}
        >
          <button className="arrowButton" onClick={DecreaseEra}>
            <img src={backwardImg} />
          </button>
          <button
            className="SelectButton"
            onClick={() => {
              setActive(!active);
            }}
          >
            {selected ? selected : "Select Era"}
          </button>
          <button className="arrowButton" onClick={IncreaseEra}>
            <img src={forwardImg} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default EraBar;
