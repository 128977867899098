import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import "./DialogBox.css";

function DialogBox({ Content, open, backgroundColor, maxWidth }) {
  return (
    <Dialog
      maxWidth={maxWidth ? maxWidth : "xs"}
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: backgroundColor
            ? backgroundColor
            : "rgba(235, 242, 250,0.8)",
          padding: "1rem",
          backdropFilter: "saturate(180%) blur(2px)",
        },
      }}
      open={open}
    >
      <div className="Content-Container">{Content}</div>
    </Dialog>
  );
}

export default DialogBox;
