export const AddUser = async (User_Email, User_Name, Invitees) => {
  var myHeaders = new Headers();

  var raw = JSON.stringify({
    user_email: User_Email,
    user_name: User_Name,
    invitee_list: Invitees,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    "https://0jvb72uk6j.execute-api.ap-south-1.amazonaws.com/default/TrackUser",
    requestOptions
  ).catch((error) => console.log("error", error));
  const result = await response.json();
  return result.data;
};

export const InviteMail = (email_list) => {
  var myHeaders = new Headers();

  var raw = JSON.stringify({
    email_list: ["surender@gamingcentral.in"],
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://h8ongn4bbl.execute-api.ap-south-1.amazonaws.com/default/inviteFriendsLataMangeshkar",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export const SendThankYouMail = (email) => {
  var myHeaders = new Headers();
  var raw = JSON.stringify({
    email_list: ["tarun@voxogenic.com"],
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://0cgqrdy5p6.execute-api.ap-south-1.amazonaws.com/default/registerApiLataMangeshkarEmail",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export const GetRoomData = async (RoomId) => {
  var myHeaders = new Headers();

  var raw = JSON.stringify({
    room_id: RoomId,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    "https://nl4az9u4md.execute-api.ap-south-1.amazonaws.com/getRoomData",
    requestOptions
  ).catch((error) => console.log("error", error));
  const result = await response.json();
  return result.data;
};

export const Contact = async (email, name, message, isSub) => {
  var myHeaders = new Headers();

  var raw = JSON.stringify({
    user_email: email,
    user_name: name,
    message: message,
    is_subscribed: isSub,
    contact_url: "https://metatribute.metaqube.ai/",
    project_id: "XR_001",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    "https://l9qd9lzorl.execute-api.ap-south-1.amazonaws.com/contactFormAPI",
    requestOptions
  ).catch((error) => console.log("error", error));
  const result = await response.json();
  return result.data;
};
