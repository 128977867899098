import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "./Share.css";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const shareurl = "https://metatribute.metaqube.ai/";
function Share({ onClose }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 40,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "white",
            fontFamily: "Source Sans Pro",
          }}
        >
          Share
        </div>
        <button
          className="closeBTN"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon fontSize="small" style={{ color: "#fff" }} />
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          fontFamily: "Source Sans Pro",
          color: "white",
        }}
      >
        <FacebookShareButton
          url={shareurl}
          quote="LataMangeskar #MetaMelodies"
          hashtag="#MetaMelodies"
        >
          <FacebookIcon round={true}></FacebookIcon>
          <div>Facebook</div>
        </FacebookShareButton>
        <WhatsappShareButton
          url={shareurl}
          quote="LataMangeskar #MetaMelodies"
          hashtag="#MetaMelodies"
        >
          <WhatsappIcon round={true}></WhatsappIcon>
          <div>What's App</div>
        </WhatsappShareButton>
        <TwitterShareButton
          url={shareurl}
          quote="LataMangeskar #MetaMelodies"
          hashtag="#MetaMelodies"
        >
          <TwitterIcon round={true}></TwitterIcon>
          <div>Twitter</div>
        </TwitterShareButton>
        <EmailShareButton
          url={shareurl}
          quote="LataMangeskar #MetaMelodies"
          hashtag="#MetaMelodies"
        >
          <EmailIcon round={true}></EmailIcon>
          <div>Email</div>
        </EmailShareButton>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <input className="ShareInput" value={shareurl} />
        <button
          className="copyButton"
          onClick={() => {
            navigator.clipboard.writeText(shareurl);
          }}
        >
          COPY
        </button>
      </div>
    </div>
  );
}

export default Share;
