import { SnackbarProvider } from "notistack";
import RoutesComponent from "./Routes/RouteComponent";

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      hideIconVariant={true}
      style={{
        backgroundColor: "rgba(1, 48, 114,0.8)",
        backdropFilter: "saturate(180%) blur(2px)",
      }}
    >
      <RoutesComponent />
    </SnackbarProvider>
  );
}

export default App;
