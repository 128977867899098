import * as BABYLON from "babylonjs";
export const showSlideShow = (mesh, images, time, scene) => {
  const textures = [];
  images.forEach((element) => {
    const texture = new BABYLON.Texture(element, scene);
    textures.push(texture);
  });
  const meshMat = new BABYLON.PBRMaterial(mesh.name, scene);
  meshMat.unlit = true;
  mesh.material = meshMat;
  display(textures, time, meshMat, mesh);
};

function display(array, time, meshMat) {
  var i = 0;
  meshMat.albedoTexture = array[i];
  setInterval(() => {
    i++;
    if (i == array.length) {
      i = 0;
    }
    MediaAnimations(meshMat, array[i]);
  }, time);
}

const MediaAnimations = (meshMat, texture) => {
  BABYLON.Animation.CreateAndStartAnimation(
    "FADEIN",
    meshMat,
    "albedoColor",
    50,
    60,
    new BABYLON.Color3.White(),
    new BABYLON.Color3.Black(),
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
    null,
    () => {
      meshMat.albedoTexture = texture;
      BABYLON.Animation.CreateAndStartAnimation(
        "FADEOUT",
        meshMat,
        "albedoColor",
        50,
        60,
        new BABYLON.Color3.Black(),
        new BABYLON.Color3.White(),
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
      );
    }
  );
};
