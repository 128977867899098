/* eslint-disable default-case */
import React, { Component } from "react";
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import assets from "../assets/LataJi4.glb";
import reticleTex from "../assets/Reticle.png";
import { enableNavigation, pointerEvents, reticle } from "./Navigator";

import DeviceDetector from "device-detector-js";

import BabylonScene from "./Scene";
import type { SceneEventArgs } from "./Scene";
import { SongsData } from "./Database";

import { generateRandomPosition, spawnPlayer, user } from "./Player";
import { getURL } from "../../../utils/constants";
import { store } from "../../../redux/redux";
import { leaveCall } from "../../../agora/agora";
import { localUserLeave } from "./Multiplayer/Network";

type props = {
  setpercent: any,
  setselected: any,
  selected: any,
  perspectiveChange: any,
  setPerspectiveChange: any,
};
class Viewer extends Component<props, {}> {
  constructor(props) {
    super(props);

    this.canvas = "";
    this.engine = "";
    this.scene = "";
    this.camera = "";
    this.light = "";
    this.assetManager = "";
    this.skyBox = "";
    this.device = null;
    this.canMove = false;
    this.fadeComponent = "";
    this.index = -1;
    this.state = {
      interactables: "",
      isLoading: true,
      sceneLoadedPercent: 0,
    };
  }

  onSceneMount = (e: SceneEventArgs) => {
    const { canvas, scene, engine } = e;
    this.canvas = canvas;
    this.engine = engine;
    this.scene = scene;

    this.setupAssetManager();
    this.setupEnv();
    this.setupStudio();

    // scene.debugLayer.show();
    engine.hideLoadingUI();

    this.assetManager.onProgress = (remainingCount, totalCount) => {
      this.props.setpercent(
        Math.floor(((totalCount - remainingCount) / totalCount) * 100)
      );
    };

    this.assetManager.onFinish = (task) => {
      const deviceDetector = new DeviceDetector();
      const userAgent = window.navigator.userAgent;
      this.device = deviceDetector.parse(userAgent);

      if (this.device.device.type === "desktop") {
        this.props.setPerspectiveChange(true);
      } else {
        console.log("smartphone");
        this.camera.upperRadiusLimit = 0;
        this.camera.lowerRadiusLimit = 0;
        this.camera.fov = 1.2;
        reticle.setEnabled(false);
        this.props.setPerspectiveChange(false);
      }
      this.fadeComponent = document.getElementById("studio");
      document.addEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
      engine.runRenderLoop(() => {
        scene.render();
      });
      this.setState({
        isLoading: false,
      });
    };
  };

  componentWillUnmount = () => {
    localUserLeave();
    leaveCall().then(() => {
      console.log("client left the call");
      this.engine.stopRenderLoop();
      console.log("rendering stopped");
    });
  };

  setupEnv = () => {
    this.camera = new BABYLON.ArcRotateCamera(
      "MainCamera",
      1.572,
      1.524,
      5,
      new BABYLON.Vector3(-0.1, 1.4, 19.2),
      this.scene
    );
    this.camera.attachControl(this.canvas, true);
    this.camera.checkCollisions = true;
    this.camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);
    this.camera.upperRadiusLimit = 5;
    this.camera.lowerRadiusLimit = 5;
    this.camera.lowerBetaLimit = 1.142;
    this.camera.upperBetaLimit = 1.97;
    this.camera.inputs.remove(this.camera.inputs.attached.keyboard);
    this.camera.inputs.remove(this.camera.inputs.attached.mousewheel);
    this.camera.inputs.attached.pointers.buttons = [0];
    this.camera.panningSensibility = 0;

    const hLight = new BABYLON.HemisphericLight(
      "hl",
      new BABYLON.Vector3(0.87, -0.059, 0.48),
      this.scene
    );

    const hdrTask = this.assetManager.addHDRCubeTextureTask(
      "",
      "/Outdoor1K.hdr",
      128,
      false
    );
    hdrTask.onSuccess = (hdrTex) => {
      this.scene.environmentTexture = hdrTex.texture;
      this.scene.environmentIntensity = 1;
    };

    new BABYLON.PhotoDome(
      "dome",
      "/OutDoor.jpg",
      {
        size: 1000,
      },
      this.scene
    );
  };

  enterExperience = () => {
    const targetLocation = new BABYLON.Vector3(0, 1.4, 0);

    BABYLON.Animation.CreateAndStartAnimation(
      "Move",
      user,
      "position",
      60,
      150,
      user.position,
      targetLocation,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
      null,
      () => {
        this.playAudio("/entranceAudio.wav");
        pointerEvents(this.scene, this.camera, reticle);
      }
    );
  };

  playAudio = (url) => {
    const audio = new BABYLON.Sound("initialAudio", url, this.scene, null, {
      autoplay: true,
    });
    audio.play();
  };

  IncreaseEra = () => {
    this.index = Object.keys(SongsData).indexOf(this.props.selected);
    if (this.index + 1 < Object.keys(SongsData).length) {
      this.index++;
      const temp = SongsData[Object.keys(SongsData)[this.index]];
      this.moveToLocation(temp.position, temp.rotation, temp.alpha, temp.beta);
    } else {
      this.index = 0;
      const temp = SongsData[Object.keys(SongsData)[this.index]];
      this.moveToLocation(temp.position, temp.rotation, temp.alpha, temp.beta);
    }
    this.props.setselected(Object.keys(SongsData)[this.index]);
  };

  DecreaseEra = () => {
    this.index = Object.keys(SongsData).indexOf(this.props.selected);
    if (this.index - 1 >= 0) {
      this.index--;
      const temp = SongsData[Object.keys(SongsData)[this.index]];
      this.moveToLocation(temp.position, temp.rotation, temp.alpha, temp.beta);
    } else if (this.index == -1) {
      this.index = 0;
      const temp = SongsData[Object.keys(SongsData)[this.index]];
      this.moveToLocation(temp.position, temp.rotation, temp.alpha, temp.beta);
    } else {
      this.index = Object.keys(SongsData).length - 1;
      const temp = SongsData[Object.keys(SongsData)[this.index]];
      this.moveToLocation(temp.position, temp.rotation, temp.alpha, temp.beta);
    }
    this.props.setselected(Object.keys(SongsData)[this.index]);
  };

  fade = (element, duration) => {
    (function increment(value = 0) {
      element.style.opacity = String(value);
      if (element.style.opacity !== "1") {
        setTimeout(() => {
          increment(value + 0.1);
        }, duration / 10);
      }
    })();
  };

  setupAssetManager = () => {
    this.assetManager = new BABYLON.AssetsManager(this.scene);
  };

  switchCam = () => {
    if (this.props.perspectiveChange === true) {
      animateCamera(this.camera, 0, 0, 1.2);
    } else {
      animateCamera(this.camera, 5, 5, 0.8);
    }

    function animateCamera(cam, upperRL, lowerRL, fov) {
      BABYLON.Animation.CreateAndStartAnimation(
        "cameraLerp",
        cam,
        "upperRadiusLimit",
        60,
        10,
        cam.upperRadiusLimit,
        upperRL,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
        null
      );
      BABYLON.Animation.CreateAndStartAnimation(
        "cameraLerp",
        cam,
        "lowerRadiusLimit",
        60,
        10,
        cam.lowerRadiusLimit,
        lowerRL,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
        null
      );
      BABYLON.Animation.CreateAndStartAnimation(
        "cameraLerp",
        cam,
        "fov",
        60,
        10,
        cam.fov,
        fov,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
        null
      );
    }
  };

  moveToLocation = (position, rotation, alpha, beta) => {
    this.fade(this.fadeComponent, 200);
    user.position = new BABYLON.Vector3(position.x, position.y, position.z);
    user._children[0].rotation = new BABYLON.Vector3(
      (Math.PI / 180) * rotation.x,
      (Math.PI / 180) * rotation.y,
      (Math.PI / 180) * rotation.z
    );
    this.camera.alpha = alpha;
    this.camera.beta = beta;
  };

  setupStudio = () => {
    enableNavigation(this.assetManager, assets, this.scene, reticleTex);

    //spawn local player
    const user = store?.getState().userData.user_id;
    const userName = store?.getState().userData.user_name;

    spawnPlayer(
      user,
      userName,
      {
        x: generateRandomPosition(-1, 1),
        y: 1.4,
        z: generateRandomPosition(18, 20),
      },
      this.camera,
      this.scene,
      getURL(),
      this.assetManager
    );
    this.assetManager.load();
  };

  render() {
    return (
      <>
        <BabylonScene onSceneMount={this.onSceneMount} />
      </>
    );
  }
}

export default Viewer;
