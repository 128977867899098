let UID = "";
let avatarID = "";
// let userdata = "";

export const setUser = (userID) => {
  UID = userID;
};

export const setURL = (id) => {
  avatarID = id;
};

export const getURL = () => {
  return avatarID;
};

export const getUser = () => {
  if (UID !== "") {
    return UID;
  }
};

export function ValidateEmail(mail) {
  if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(mail)) {
    return true;
  }
  return false;
}
