import React, { useEffect, useRef, useState } from "react";
import ExperienceUI from "./ExperienceUI/ExperienceUI";
import Viewer from "./scene/Viewer";

const Studio = () => {
  const viewerRef = useRef();
  const [percent, setpercent] = useState(0);
  const [selected, setselected] = useState(null);
  const [perspectiveChange, setPerspectiveChange] = useState(false);
  const moveToLocation = (position, rotation, alpha, beta) => {
    viewerRef.current.moveToLocation(position, rotation, alpha, beta);
  };
  const IncreaseEra = () => {
    viewerRef.current.IncreaseEra();
  };
  const DecreaseEra = () => {
    viewerRef.current.DecreaseEra();
  };

  const switchCam = () => {
    viewerRef.current.switchCam();
  };
  const enterExperience = (onEnd) => {
    viewerRef.current.enterExperience(onEnd);
  };

  return (
    <div>
      <Viewer
        setpercent={setpercent}
        ref={viewerRef}
        setselected={setselected}
        selected={selected}
        perspectiveChange={perspectiveChange}
        setPerspectiveChange={setPerspectiveChange}
      />
      <ExperienceUI
        percent={percent}
        moveToLocation={moveToLocation}
        IncreaseEra={IncreaseEra}
        DecreaseEra={DecreaseEra}
        selected={selected}
        setselected={setselected}
        switchCam={switchCam}
        setPerspectiveChange={setPerspectiveChange}
        perspectiveChange={perspectiveChange}
        enterExperience={enterExperience}
      />
    </div>
  );
};

export default Studio;
