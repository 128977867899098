import { RtcRole } from "agora-access-token";

const agoraAccessToken = require("agora-access-token");

export const generateRtcToken = (channelName, uid) => {
  const role = RtcRole.PUBLISHER;
  const expirationTimeInSeconds = 86400;
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const privilegeExpiredTs = currentTimestamp + expirationTimeInSeconds;

  return agoraAccessToken.RtcTokenBuilder.buildTokenWithUid(
    "bba9e4f54cde41e0bcab9aeb2866c6ae",
    "f49db0343dc247148d68a5e66b077fe2",
    channelName,
    uid,
    role,
    privilegeExpiredTs
  );
};
