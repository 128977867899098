import * as BABYLON from "babylonjs";
import { avatarURLs } from "./Database";
import { addNamePlate, joinRoom } from "./Multiplayer/Network";
import "babylonjs-loaders";
import { getURL } from "../../../utils/constants";

var inputMap = {};
export let user = null;

export const spawnPlayer = async (
  userid,
  name,
  position,
  camera,
  scene,
  avatarID,
  assetManager
) => {
  let URL = "";

  for (let index = 0; index < avatarURLs.length; index++) {
    const element = avatarURLs[index];
    if (element.id === avatarID) {
      URL = element.url;
    }
  }

  const localPlayerTask = assetManager.addMeshTask("Player Task", "", URL, "");
  localPlayerTask.onSuccess = (mesh) => {
    user = mesh.loadedMeshes[0];
    user.enableCollisions = true;
    user.name = userid;
    user.position = new BABYLON.Vector3(position.x, position.y, position.z);
    user._children[0].rotation = new BABYLON.Vector3(0, Math.PI, 0);
    camera.setTarget(new BABYLON.Vector3(0, 0.2, 0));
    camera.parent = user;
    const plane = user._children[0]._children[1];
    // const parent = scene.getTransformNodeByName("mixamorig:HeadTop_End");
    plane.setEnabled(false);
    plane.material.backFaceCulling = false;
    plane.name = userid + "_userVideo";
    addShadowToPlayer(user._children[0], scene);
    user.scalingDeterminant = 0.5;
    user.scaling = new BABYLON.Vector3(-1, 1, -1);
    addNamePlate("Me", user, scene, true);
    joinRoom(camera, scene, getURL());
  };
};

export const generateRandomPosition = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const addShadowToPlayer = (user, scene) => {
  let shadow = new BABYLON.MeshBuilder.CreatePlane("shadow", {
    size: 1,
    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
  });
  shadow.isPickable = false;
  shadow.position = new BABYLON.Vector3(0, -0.9, 0);
  shadow.rotation = new BABYLON.Vector3(-Math.PI / 2, 0, 0);
  shadow.parent = user;

  const mat = new BABYLON.StandardMaterial("shadowmat", scene);
  mat.opacityTexture = new BABYLON.Texture("/Shadow_Map_Avatar.png", scene);
  shadow.material = mat;
};

export const keyboardEvents = (scene) => {
  scene.actionManager = new BABYLON.ActionManager(scene);
  scene.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnKeyDownTrigger,
      function (evt) {
        inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
      }
    )
  );
  scene.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnKeyUpTrigger,
      function (evt) {
        inputMap[evt.sourceEvent.key] = "";
      }
    )
  );
};

export const inputController = (camera) => {
  if (inputMap["s"]) {
    user.moveWithCollisions(user.forward.scaleInPlace(0.03));
  }
  if (inputMap["w"]) {
    user.moveWithCollisions(user.forward.scaleInPlace(-0.01));
  }
  if (inputMap["a"]) {
    user.addRotation(0, -0.01, 0);
  }
  if (inputMap["d"]) {
    user.addRotation(0, 0.01, 0);
  }
};
